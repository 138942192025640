<template>
  <div class="app-container">
    <div class="top_box">
      <el-form class="el-form-box" ref="formMessage" :model="formMessage" v-loading="formMessageLoading"
        @submit.native.prevent>
        <el-form-item label="" prop="key">
          <el-input v-model="formMessage.input" type="textarea" :autosize="{ minRows: 4, maxRows: 4 }"
            placeholder="请输入要编辑的内容,例：现代社会非常好" />
          <el-input style="margin-top: 10px;" v-model="formMessage.instruction" :autosize="{ minRows: 4, maxRows: 4 }"
            placeholder="请输入修改说明，例：把现代改成古代" />
        </el-form-item>
      </el-form>
      <el-button style="width: 100%;" slot="append" :icon="submitIcon" type="primary" size="medium" v-preventReClick
        @click="send">确定</el-button>

    </div>
    <div class="showarea" v-if="messages">
      <p style="padding-bottom: 20px;text-align: center;color: #1890ff;">以下为编辑后的内容</p>
      {{ messages }}
    </div>
  </div>
</template>
<script>
import { textEdit } from '@/api/ai/text'
import 'highlight.js/styles/monokai-sublime.css'
export default {
  name: 'AiChat',
  data() {
    return {
      submitIcon: '',
      formMessageLoading: false,
      formMessage: {
        input: '',
        instruction: ''
      },
      messages: ''
    }
  },
  methods: {
    send() {
      // 翻译按钮
      if (this.submitIcon == 'el-icon-loading') return
      if (!this.formMessage.input) return
      this.submitIcon = 'el-icon-loading'
      textEdit({
        input: this.formMessage.input,
        instruction: this.formMessage.instruction
      }).then((res) => {
        this.submitIcon = ''
        if (res.data.success) {
          // 找到最后一个message，进行content替换
          // eslint-disable-next-line no-eval
          let msg = JSON.parse(res.data.result)
          this.messages = msg.text
        } else {
          this.$msgError(res.data.message)
        }
      })
    }
  },
}
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}

.app-container {
  overflow: hidden;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.top_box {
  margin-top: 10px;
}

.el-form-box {
  overflow: hidden;
  background: #fff;
  margin: 0 auto;
}

.el-form-item {
  margin-bottom: 10px;
}

.showarea {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  line-height: 24px;
  color: #333;
  background: #f8f8f8;
  padding: 20px;
  line-height: 24px;
}

@media only screen and (max-width: 768px) {
  [class*="top_box"] {
    width: 100%;
    background: #fff;
  }

  [class*="showarea"] {
    width: 100%;
  }

  [class*="el-form-box"] {
    width: 100%;
  }

  [class*="showarea"] {
    width: 100%;
  }
}

.el-main {
  padding: 10px;
}
</style>