import {request,post,getStream} from '@/plugins/request';

/**
 * 文字润色
 * sample
 * data={
 *   "input":"What day of the wek is it?",
 *   "instruction":"Fix the spelling mistakes"
 *  }
 * -----------------------------------
 *  Comments:  
 *    input: 预编辑文本
 *        例如：现任总统照片
 *    instruction: 文本编辑说明
 *        例如：把现任更改问历任
 */
export function textEdit(data) {
    return post('/aichat/v0.1/chat/chat/ct/text/edit',data);
}